
















































import Vue from 'vue'
import Component from 'vue-class-component'
import QuickAccessModule from '@/store/modules/quick-access'
import CommonModule from '@/store/modules/common'
import { Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import PackCard from '@/components/common/packs/card/index.vue'
import { PackSize } from '@/modules/common'

@Component({
  components: { PackCard }
})
export default class ByoConfirm extends Vue {
  quickAccessModule: QuickAccessModule = getModule(QuickAccessModule, this.$store)

  commonModule: CommonModule = getModule(CommonModule, this.$store)

  loaded = false

  more = false

  @Prop()
  private contactId!: string

  /* Next Pack */
  get nextPack() {
    return this.quickAccessModule.activeConfiguration
  }

  get packName() {
    return this.quickAccessModule.packName
  }

  get hasPendingOrder() {
    return this.quickAccessModule.hasPendingOrder
  }

  get redo() {
    return `/apps/quick-access/${this.contactId}/byo`
  }

  /* Featured & Popular Pack Cards */
  get frequency() {
    return this.quickAccessModule.frequency
  }

  // get freeItem() {
  //   if (this.quickAccessModule.freeItems[0]) {
  //     return this.quickAccessModule.freeItems[0]
  //   }
  //   return null
  // }

  get discount() {
    return this.quickAccessModule.discount
  }

  get packSize() {
    if (this.quickAccessModule.newPackSize !== PackSize.Unknown) {
      return this.quickAccessModule.newPackSize
    }
    return this.quickAccessModule.packSize
  }

  get firstName() {
    return this.quickAccessModule.firstName
  }

  get featuredPacks() {
    return this.quickAccessModule.featuredPacks
  }

  get proteins() {
    if (this.quickAccessModule.subscriptionDetails) {
      return this.quickAccessModule.customizedPackProteins
    }
    return []
  }

  // eslint-disable-next-line class-methods-use-this
  async selected() {
    this.$emit('toggle-update-pack')
    // TODO NEW await this.quickAccessModule.UpdateNextPackWithPreconfiguredItems(input)
    this.$emit('toggle-update-pack')
  }

  get nextWeek() {
    return this.quickAccessModule.nextPack
  }

  async mounted() {
    await this.commonModule.SetQuickActionTitle('Success. Looks Tasty!')
    await this.quickAccessModule.Startup(this.contactId)
    this.loaded = true
  }
}
