


























































































































import Vue from 'vue'
import Component from 'vue-class-component'
import QuickAccessModule from '@/store/modules/quick-access'
import CommonModule from '@/store/modules/common'
import { Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import PackCard from '@/components/common/packs/card/index.vue'
import { PackSize } from '@/modules/common'

@Component({
  components: { PackCard }
})
export default class ByoStart extends Vue {
  quickAccessModule: QuickAccessModule = getModule(QuickAccessModule, this.$store)

  commonModule: CommonModule = getModule(CommonModule, this.$store)

  loaded = false

  more = false

  @Prop()
  private contactId!: string

  get nextPack() {
    return this.quickAccessModule.nextPack
  }

  get currentConfig() {
    return this.quickAccessModule.currentConfiguration
  }

  get hasPendingOrder() {
    return this.quickAccessModule.hasPendingOrder
  }

  get frequency() {
    return this.quickAccessModule.frequency
  }

  get isReadonly() {
    return this.loaded && this.hasPendingOrder
  }

  get isSubscriptionPaused() {
    return this.loaded && this.quickAccessModule.hasPausedSubscription
  }

  get isSubscriptionSuspended() {
    return this.loaded && this.quickAccessModule.hasSuspendedSubscription
  }

  get isSubscriptionCancelled() {
    return this.loaded && this.quickAccessModule.hasCanceledubscription
  }

  get hasActiveSubscription() {
    return this.loaded && this.quickAccessModule.hasActiveSubscription
  }

  // get freeItem() {
  //   if (this.quickAccessModule.freeItems[0]) {
  //     return this.quickAccessModule.freeItems[0]
  //   }
  //   return null
  // }

  get surcharge() {
    return this.currentConfig.surcharge
  }

  get discount() {
    return this.currentConfig.discount
  }

  get nonDiscountedPrice() {
    return this.currentConfig.listPrice
  }

  get discountedPrice() {
    return this.nonDiscountedPrice - this.discount + this.surcharge
  }

  get packSize() {
    return this.currentConfig.activeTemplate.packSize
  }

  get firstName() {
    return this.quickAccessModule.firstName
  }

  get featuredPacks() {
    return this.quickAccessModule.featuredPacks
  }

  get proteins() {
    if (this.quickAccessModule.customizedPackProteins) {
      return this.quickAccessModule.customizedPackProteins
    }
    return []
  }

  // eslint-disable-next-line class-methods-use-this
  async selected(input: { id: string; personalize: boolean; size: PackSize }) {
    if (!input.personalize) {
      this.$emit('toggle-update-pack')
      await this.quickAccessModule.UpdateNextPackTemplate(input)
      this.$emit('toggle-update-pack')
      this.$router.push({
        name: 'quick-access.byo.confirm',
        params: { contactId: this.contactId }
      })
    } else {
      await this.quickAccessModule.CustomizePreconfiguredCustomPack(input)
      this.$router.push({
        name: 'quick-access.byo.customize',
        params: { contactId: this.contactId }
      })
    }
  }

  get nextWeek() {
    return this.quickAccessModule.nextPack
  }

  async mounted() {
    await this.commonModule.SetQuickActionTitle('Build Your Perfect Pack!')
    await this.quickAccessModule.Startup(this.contactId)
    if (this.quickAccessModule.hasPendingOrder) {
      this.$router.push({
        name: 'quick-access.byo.pending',
        params: { contactId: this.contactId }
      })
    }
    this.loaded = true
  }
}
