

























import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class QuickAccess extends Vue {
  @Prop()
  contactId!: string | undefined;

  overlay = false;

  toggleUpdating() {
    this.overlay = !this.overlay
  }

  pickProteins() {
    this.$router.push({
      name: 'quick-access.byo.pick-proteins',
      params: { contactId: this.contactId! }
    })
  }
}
