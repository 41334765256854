









import Vue from 'vue'
import Component from 'vue-class-component'
import QuickAccessModule from '@/store/modules/quick-access'
import { Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import CustomProteinSelector from '@/components/common/CustomProteinSelector/index.vue'
import { ProteinType } from '@/modules/common'

@Component({
  components: { CustomProteinSelector }
})
export default class ByoPickProteins extends Vue {
  quickAccessModule: QuickAccessModule = getModule(QuickAccessModule, this.$store)

  loaded = false

  more = false

  @Prop()
  private contactId!: string

  get packSize() {
    return this.quickAccessModule.packSize
  }

  async proteinsSelected(selectedProteins: Array<ProteinType>) {
    this.$emit('toggle-update-pack')

    await this.quickAccessModule.CustomizeWithSelectedProteins({
      selectedProteins
    })
    this.$router.push({
      name: 'quick-access.byo.unboxed',
      params: { contactId: this.contactId }
    })
    this.$emit('toggle-update-pack')
  }

  async mounted() {
    await this.quickAccessModule.Startup(this.contactId)
    this.loaded = true
  }
}
