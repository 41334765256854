

























import QuickAccessModule from '@/store/modules/quick-access'
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'
import ItemSelectorList from '@/components/common/ItemSelectorList/index.vue'
import { Prop } from 'vue-property-decorator'

@Component({
  components: { ItemSelectorList }
})
export default class ByoStart extends Vue {
  quickAccessModule: QuickAccessModule = getModule(QuickAccessModule, this.$store)

  @Prop()
  private contactId!: string

  private loaded = false

  private get minPurchasePrice() {
    return `$${this.quickAccessModule.pricingDetails.listPrice.toFixed(2)}`
  }

  private get currentSurcharge() {
    if (this.quickAccessModule.totalUnboxedSurcharge > 0) {
      return `$${this.quickAccessModule.totalUnboxedSurcharge.toFixed(2)}`
    }
    return null
  }

  private get currentDiscount() {
    if (this.quickAccessModule.pricingDetails.discount > 0) {
      return `$${this.quickAccessModule.pricingDetails.discount.toFixed(2)}`
    }
    return null
  }

  private get currentPurchasePrice() {
    return `$${this.quickAccessModule.nextPackPurchasePrice.toFixed(2)}`
  }

  private get unboxedAddItems() {
    return this.quickAccessModule.unboxedAddItems
  }

  private get unboxedProgessMessage() {
    if (this.quickAccessModule.isUnboxed) {
      return this.quickAccessModule.unboxedProgessMessage
    }
  }

  private get moneyLeft() {
    if (this.quickAccessModule.isUnboxed) {
      return this.quickAccessModule.moneyLeft
    }
    return 0
  }

  private get unboxedProgress() {
    if (this.quickAccessModule.isUnboxed) {
      return this.quickAccessModule.unboxedProgress
    }
    return 0
  }

  private get packItems() {
    if (
      this.quickAccessModule.currentConfiguration.activeTemplate.items &&
      this.quickAccessModule.isUnboxed === false
    ) {
      return this.quickAccessModule.currentConfiguration.activeTemplate.items
    }
    if (this.quickAccessModule.currentConfiguration.activeTemplate.items && this.quickAccessModule.isUnboxed) {
      return this.quickAccessModule.unboxedItems
    }
    return []
  }

  private get isUnboxed() {
    return this.quickAccessModule.isUnboxed
  }

  private get packProteins() {
    if (this.quickAccessModule.customizedPackProteins) {
      return this.quickAccessModule.customizedPackProteins
    }
    return []
  }

  private get packName() {
    return this.quickAccessModule.packName
  }

  private subsituteItem(input: { itemIndex: number; newItemId: string; surcharge: number }) {
    this.quickAccessModule.SwapItem(input)
  }

  private increaseItemQuantity(input: { itemId: number }) {
    this.quickAccessModule.IncreaseItemQuantity(input)
  }

  private decreaseItemQuantity(input: { itemId: number }) {
    this.quickAccessModule.DecreaseItemQuantity(input)
  }

  async mounted() {
    if (this.quickAccessModule.currentConfiguration.activeTemplate.items.length === 0) {
      await this.quickAccessModule.Startup(this.contactId)
      await this.quickAccessModule.CustomizePreconfiguredCustomPack({
        id: this.quickAccessModule.currentConfiguration.activeTemplate.productConfigurationTemplateId,
        size: this.quickAccessModule.packSize
      })
    }

    this.loaded = true
  }

  private async processCustomizations() {
    this.$emit('toggle-update-pack')
    await this.quickAccessModule.UpdateNextPackTemplate({
      id: this.quickAccessModule.currentConfiguration.activeTemplate.productConfigurationTemplateId,
      size: this.quickAccessModule.currentConfiguration.activeTemplate.packSize
    })
    this.$emit('toggle-update-pack')
    this.$router.push({
      name: 'quick-access.byo.confirm',
      params: { contactId: this.contactId }
    })
  }
}
