







































import Vue from 'vue'
import Component from 'vue-class-component'
import CommonModule from '@/store/modules/common'
import { getModule } from 'vuex-module-decorators'
import QuickAccessModule from '@/store/modules/quick-access'
import PackCard from '@/components/common/packs/card/index.vue'

@Component({
  components: { PackCard }
})
export default class ByoPending extends Vue {
  commonModule: CommonModule = getModule(CommonModule, this.$store)

  quickAccessModule: QuickAccessModule = getModule(QuickAccessModule, this.$store)

  loaded = false

  get packSize() {
    return this.quickAccessModule.packSize
  }

  get packName() {
    return `${this.quickAccessModule.firstName}'s Delivery`
  }

  get nextPack() {
    return this.quickAccessModule.activeConfiguration
  }

  // get freeItem() {
  //   if (this.quickAccessModule.freeItems[0]) {
  //     return this.quickAccessModule.freeItems[0]
  //   }
  //   return null
  // }

  /* Featured & Popular Pack Cards */
  get frequency() {
    return this.quickAccessModule.frequency
  }

  async mounted() {
    await this.commonModule.SetQuickActionTitle('Your Order Is In Progress')
    this.loaded = true
  }
}
