












































import Vue from 'vue'
import Component from 'vue-class-component'
import QuickAccessModule from '@/store/modules/quick-access'
import { Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import SignupModule from '@/store/modules/signup'

@Component({})
export default class ByoUnboxed extends Vue {
  quickAccessModule: QuickAccessModule = getModule(QuickAccessModule, this.$store)
  signUpModule: SignupModule = getModule(SignupModule, this.$store)
  loaded = false

  more = false

  @Prop()
  private contactId!: string

  get packPrice() {
    return this.signUpModule.standardPrice.toFixed(2)
  }

  async unboxed() {
    await this.quickAccessModule.EnableUnboxedMode()
    this.$router.push({
      name: 'quick-access.byo.customize',
      params: { contactId: this.contactId }
    })
  }

  async classic() {
    await this.quickAccessModule.EnableClassicMode()
    this.$router.push({
      name: 'quick-access.byo.customize',
      params: { contactId: this.contactId }
    })
  }

  async mounted() {
    // await this.quickAccessModule.Startup(this.contactId)
    this.loaded = true
  }
}
